import React, { useState, useEffect } from 'react';

const MyplanUpdate = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const superAdminStatus = JSON.parse(sessionStorage.getItem("is_super_admin"));
    const adminStatus = JSON.parse(sessionStorage.getItem("is_admin"));

    setIsSuperAdmin(superAdminStatus);
    setIsAdmin(adminStatus);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', backgroundColor: '#f5f5f5' }}>
      <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '2rem', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <h1 style={{ fontSize: '2rem', color: '#333' }}>Upgrade Required</h1>
        <p style={{ fontSize: '1rem', color: '#666' }}>
          You are currently subscribed to the Standard Plan. The feature you are trying to access is not included in your current plan.
        </p>
        <p style={{ fontSize: '1rem', color: 'red' }}>
          {(isSuperAdmin || isAdmin) ?
            "To use this feature, please upgrade to the Premium Plan." :
            "To access this feature, please reach out to your admin and request them to subscribe to the relevant package."
          }
        </p>
        {(isSuperAdmin || isAdmin) && (
          <button style={{
            marginTop: '1.5rem',
            padding: '0.75rem 2rem',
            backgroundColor: 'rgb(4, 183, 177)',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem'
          }}
            onClick={() => window.location.href = '/myplans'} // Redirect to the subscription page
          >
            Subscribe Now
          </button>
        )}
      </div>
    </div>
  );
};

export default MyplanUpdate;
