
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, Table, Tooltip, Space } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import MasterFilter from "pages/master/MasterFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeLeavePolicyPagination } from "hooks/api/masterApi/employeeLeavePolicyApi";
import { EMPLOYEE_LEAVE_POLICY } from 'constants';
import { checkUserPermissionFunc, deleteDraftItemsById } from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
  leave_policy_code: "",
  leave_policy_name: "",
  // leave_expiry: "",
  // leave_carry_forward: "",
  leave_policy_is_active: "",
}

function EmployeeLeavePolicy() {
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const authtoken = sessionStorage.getItem("token");
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = isDraft ? "77.5vh" : "72vh";

  const navigate = useNavigate();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const userPermission = checkUserPermissionFunc("master", "Leave Type", "SubMenu");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getEmployeeLeavePolicyPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message, _message.errorMsgText]);

  const addClick = () => {
    setEditVisible(false);
    setSelectedDetails({});
    navigate('/master/employeeleavepolicy/add', { state: { fromEdit: false, editDetails: {}, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!")
    }
    else if (!editVisible) {
      setEditVisible(true);
      navigate('/master/employeeleavepolicy/add', { state: { fromEdit: true, editDetails: details, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
      setSelectedDetails(details);
    }
  };

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `code=${data.leave_policy_code ? data.leave_policy_code : ""}&leave_name=${data.leave_policy_name ? data.leave_policy_name : ""}&active_status=${data.leave_policy_is_active !== undefined ? data.leave_policy_is_active : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const columns = [
    {
      title: "Leave Policy Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: "16.41%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.code?.length > 20 ?
          <Tooltip title={record.code}>{<span style={{ color: colors }}>{record.code?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.code ? record.code : ""}</p>)
      },
    },
    {
      title: "Leave Name",
      dataIndex: "code",
      key: "leave_name",
      sorter: true,
      width: "17.41%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.leave_name?.length > 20 ?
          <Tooltip title={record.leave_name}>{<span style={{ color: colors }}>{record.leave_name?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.leave_name ? record.leave_name : ""}</p>)
      },
    },
    {
      title: "No Of Days",
      dataIndex: "no_of_days",
      key: "no_of_days",
      sorter: true,
      width: "10%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (<p style={{ color: colors }}>{record.no_of_days ? record.no_of_days : ""}</p>)
      },
      align: 'right',
      fixed: 'left',
    },
    {
      title: "Leave Expiry",
      dataIndex: "leave_expiry",
      key: "leave_expiry",
      sorter: true,
      width: "14.94%",
      align: 'center',
      fixed: "left",
      render: (_, record) =>
      (<span style={{ color: record.is_active === true ? "black" : "#CACACA" }}>
        {record.leave_expiry ? `${record.leave_expiry} ${record.leave_expiry === 1 ? "Month" : "Months"}` : "-"}</span>),
    },
    {
      title: 'Leave Carry Forward',
      dataIndex: 'leave_carry_forward',
      key: 'leave_carry_forward',
      sorter: true,
      width: "16.24%",
      fixed: "left",
      render: (_, record) => (<span style={{ color: record.is_active === true ? "black" : "#CACACA" }}>{record.leave_carry_forward ? "Yes" : "No"}</span>)
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      sorter: true,
      width: "12%",
      fixed: "left",
      render: (_, record) =>
        record.is_active === true ? (
          <span style={{ color: "black" }}>Active</span>
        ) : (
          <span style={{ color: "#CACACA" }}>Inactive</span>
        ),
      align: 'center',
    },
  ];

  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: 'center',
      width: "10%",
      fixed: "left",
      render: (_, record) => (
        <div className="flex justify-center items-center">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'ug_btn_edit'}
            />
            {record.is_draft === true ?
              <IconButton
                title="Delete"
                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                testID={'ug_btn_delete'}
              /> : null}
          </Space>
        </div>
      ),
    })
  }

  //draft delete start  
  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (id) => {
    try {
      const apiData = await deleteDraftItemsById(id, EMPLOYEE_LEAVE_POLICY);
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        handleDraft();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  }//draft delete end

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = ""
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&code=" + (filterDatas.leave_policy_code ? filterDatas.leave_policy_code : "") +
    "&leave_name=" + (filterDatas.leave_policy_name ? filterDatas.leave_policy_name : "") +
    "&active_status=" + (filterDatas.leave_policy_is_active !== undefined ? filterDatas.leave_policy_is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Leave Type"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          handleDraft={handleDraft} isDraft={isDraft} handleBackToHome={handleBackToHome}
          url={EMPLOYEE_LEAVE_POLICY} query={queryString}
          isAddRequired={userPermission?.add ? true : false}
          isDraftRequired={userPermission?.draft ? true : false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <MasterFilter
              pageName={"LeaveType"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
          />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
    </Card>
  );
}

export default EmployeeLeavePolicy;
